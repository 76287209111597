<template>
  <div>
    <div class="vx-row mb-base">
      <div class="vx-col w-full md:w-1/3">
        <gs-statistics-card-count
          :fetch-data-function="fetchCountContacts"
          :title="$t('AllContacts')"
          icon="UserIcon"/>
      </div>
      <div class="vx-col w-full mt-3 md:mt-0 md:w-1/3">
        <gs-statistics-card-count
          :fetch-data-function="fetchCountContactsFilters"
          :title="$t('SubscribedContacts')"
          icon="UserCheckIcon"
          color="success"
          :fetch-filters="subscribeFilter"
          :fetch-filters-match="$enums.AppFilterMathType.ALL"/>
      </div>
      <div class="vx-col w-full mt-3 md:mt-0 md:w-1/3">
        <gs-statistics-card-count
          :fetch-data-function="fetchCountContactsFilters"
          :title="$t('UnsubscribedContacts')"
          icon="UserXIcon"
          color="danger"
          :fetch-filters="unsubscribeFilter"
          :fetch-filters-match="$enums.AppFilterMathType.ALL"/>
      </div>
    </div>

    <vx-card
      :title="$t('$DashboardAnalyticsPage.ContactsAnalyticsCardTitle')"
      collapse-action
      class="mt-base">
      <vs-tabs>
        <vs-tab
          :label="$t('$DashboardAnalyticsPage.ContactsBehaviorTabsTitle')">
          <gs-line-chart
            :series-info="contactsLinesSeriesInfo"
            :fetch-data-function="fetchAnalyticsLinesContacts"
            :y-axis-title="$tc('$Entities.Contact', 2)"
            :no-data-title="$t('$DashboardAnalyticsPage.ContactsLineChartsNoDataTitle')"
            :no-data-subtitle="$t('$DashboardAnalyticsPage.ContactsLineChartsNoDataSubtitle')"/>
        </vs-tab>

        <vs-tab
          :label="$t('$DashboardAnalyticsPage.ContactsMarketingStatusTabsTitle')">
          <gs-pie-chart
            :series-info="contactsPieSeriesInfo"
            :fetch-data-function="fetchAnalyticsPieContacts"
            :no-data-title="this.$t('$DashboardAnalyticsPage.ContactsPieChartsNoDataTitle')"
            :no-data-subtitle="this.$t('$DashboardAnalyticsPage.ContactsPieChartsNoDataSubtitle')"/>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import GsStatisticsCardCount from '@/views/charts-and-maps/charts/GsStatisticsCardCount.vue';
import GsLineChart from '@/views/charts-and-maps/charts/GsLineChart.vue';
import GsPieChart from '@/views/charts-and-maps/charts/GsPieChart.vue';
import themeConfig from '../../../themeConfig';

/**
 * Contact analytics
 *
 * @module views/analytics/ContactsAnalytics
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object[]} contactsLinesSeriesInfo - contacts lines series info
 * @vue-data {Object[]} contactsPieSeriesInfo - contacts pie series info
 */
export default {
  name: 'ContactsAnalytics',
  i18n: {
    messages: {
      en: {
        AllContacts: 'All contacts',
        SubscribedContacts: 'Subscribed contacts',
        UnsubscribedContacts: 'Unsubscribed contacts',
      },
    },
  },
  components: {
    GsStatisticsCardCount,
    GsLineChart,
    GsPieChart,
  },
  data() {
    return {
      contactsLinesSeriesInfo: [{
        name: this.$tc('$Entities.Contact', 2),
        key: 'numberContacts',
        color: themeConfig.colors.primary,
      }],
      contactsPieSeriesInfo: [
        {
          name: this.$t('$General.Subscribed'),
          key: 'contactsSubscribed',
          color: themeConfig.colors.success,
        },
        {
          name: this.$t('$General.Unsubscribed'),
          key: 'contactsUnsubscribed',
          color: themeConfig.colors.danger,
        },
      ],
      subscribeFilter: {
        marketingStatus: {
          filterType: this.$enums.AppFilterType.TEXT,
          type: this.$enums.AppFilter.FilterType.Text.Type.EQUALS,
          filter: this.$enums.Contact.MarketingStatus.SUBSCRIBED,
          filterInner: 'value',
        },
      },
      unsubscribeFilter: {
        marketingStatus: {
          filterType: this.$enums.AppFilterType.TEXT,
          type: this.$enums.AppFilter.FilterType.Text.Type.EQUALS,
          filter: this.$enums.Contact.MarketingStatus.UNSUBSCRIBED,
          filterInner: 'value',
        },
      },
    };
  },
  methods: {
    ...mapActions({
      fetchCountContacts: 'contact/fetchCountContacts',
      fetchCountContactsFilters: 'contact/fetchCountContactsFilters',
      fetchAnalyticsLinesContacts: 'contact/fetchAnalyticsLines',
      fetchAnalyticsPieContacts: 'contact/fetchAnalyticsPie',
    }),
  },
};
</script>

<style scoped>

</style>
